import { Group, Loader, Progress, Skeleton, Stack, Table, Tooltip } from '@mantine/core';
import ProfileImage from '../../../assests/svg/TestImage.svg';
import HighConsumption from '../../../assests/svg/HighConsumption.svg';
import LowConsumption from '../../../assests/svg/LowConsumption.svg';
import ConsumptionIcon from '../../../assests/svg/Consumption.svg';
import messagesIcon from '../../../assests/svg/MessagesIcon.svg';
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { tenantConsumptionGet } from '../../../helpers/fetchApis/MeterConsumptions';
import { set_loader, set_skeleton_loader } from '../../../redux/action/General/generalActions';
import dayjs from 'dayjs';
import { useState } from 'react';
import EmptyState from './EmptyState';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { api_config } from '../../../helpers/apis';
import { getAuthToken } from '../../../helpers/Uitls';
import customLoader from '../HelperComponents/CustomLoader';
import { MessagesGet } from '../../../helpers/fetchApis/Messages';
import TileDetailsModal from '../Modals/TileDetailsModal';
import { NotesGet } from '../../../helpers/fetchApis/Properties';

const DashboardTilesCard = (props) => {
    let navigate= useNavigate();
    const [loader, setLoader] = useState(false);
    const [openTileDetailModal, setOpenTileDetailModal] = useState(false);
    
    
    // const getMessagesByPropertyId=()=> {
    //     const {account, profile, property} = props;
    //     setLoader(true);
    //     MessagesGet(property.id, res=> {
    //         if(Array.isArray(res)){
    //             setMessagesData(res);
    //         }else{
    //             if(res.status === 429){
    //                 setLoader(true);
    //                 setTimeout(() => {
    //                     getMessagesByPropertyId();
    //                 }, 3000);
    //             }
    //         }
    //         setLoader(false);
    //     });
    // }

    useEffect(() => {
        // getMessagesByPropertyId();
        //getNotesByPropertyId();
    }, []);

  return (
    <Stack className='dashboard-general-card' onClick={()=> setOpenTileDetailModal(true)} style={{minWidth: '360px', cursor: 'pointer'}} justify='space-around' spacing={'lg'}>
        {<>
            <Group position='apart'>
                <Group noWrap align='flex-end'>
                    <span className='dashboard-general-card-heading'>Aushang</span>
                </Group>
            </Group>
            {loader ?
            <Stack style={{height: 'inherit', marginTop: '18px'}} spacing={40}>
                {[...Array(3)].map((e, i) => 
                    <Group noWrap position='apart'>
                        <Skeleton style={{width: '50%', height: '20px'}}/>
                        <Skeleton style={{width: '25%', height: '20px'}}/>
                    </Group>
                )}
            </Stack>
            : props.tile !== null &&
            <Stack style={{overflow: 'hidden'}}>
                {props.tile.title && <span className='tile-preview-title'>{props.tile.title}</span>}
                <div className='tile-preview-body' style={{height: '110px'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
            </Stack>}
            <Group position='right'>
                <Group className='dashboard-general-card-all' onClick={()=> setOpenTileDetailModal(true)} align={'flex-end'}>
                    <span style={{cursor: 'pointer'}}>Mehr anzeigen</span>
                </Group>
            </Group>
        </>}
        {openTileDetailModal &&
        <TileDetailsModal
        open={openTileDetailModal}
        toggle={()=> setOpenTileDetailModal(false)}
        tile={props.tile}
        />}
    </Stack> 
  )
}
const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      property: state.generalReducer.propertyData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet,
      skeletonLoader: state.generalReducer.skeletonLoader
    };
  }
  
  
  const actions={
    set_loader,
    set_skeleton_loader
}
export default connect(mapStateToProps, actions)(DashboardTilesCard);