import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput } from '@mantine/core';
import {set_profile_data, set_account_data, set_token, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import ProfileIcon from '../../../assests/svg/ProfileIcon.svg';
//import TenantIconBlue from '../../../../assests/svg/TenantIconBlue.svg';
import { check_auth } from '../../../helpers/Uitls';
import { SignInPost } from '../../../helpers/fetchApis/Auth';
import { AccountGet } from '../../../helpers/fetchApis/Accounts';
import HeadingContainer from '../../components/CommonComponents/HeadingContainer';
import { UserPasswordPut, UserPut } from '../../../helpers/fetchApis/Users';
import { showNotification } from '@mantine/notifications';
import EmailVerificationModal from '../../components/Modals/EmailVerificationModal';
import ChangeUserEmailConfirmationModal from '../../components/Modals/ChangeUserEmailConfirmationModal';

class Profile extends Component {

    state={
        firstName: '',
        lastName: '',
        oldEmail: '',
        email: '',
        openEmailVerificationModal: false,
        currentPassword: '',
        newPassword: '',
        openChangeUserEmailConfirmationModal: false
    }
    constructor(props) {
        super(props)
    }
    
    handleLogout = () => {
        localStorage.clear();
        window.location.href = "/login";
    }

    componentDidMount() {
        console.log('profile: ', this.props.profile);
        const {profile} = this.props;
        this.setState({
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            oldEmail: profile.email
        });
    }

    handleFirstNameChange =(e)=>{
        e.preventDefault();
        this.setState({firstName: e.currentTarget.value});
    }

    handleLastNameChange =(e)=>{
        e.preventDefault();
        this.setState({lastName: e.currentTarget.value});
    }

    handleEmailChange =(e)=>{
        e.preventDefault();
        this.setState({email: e.currentTarget.value});
    }

    handleUpdate=()=>{
        const {profile, set_profile_data} = this.props;
        const {firstName, lastName, email, oldEmail} = this.state;
        let payload={
            ...profile,
            // firstName: firstName,
            // lastName: lastName,
            email: email
        }

        if(email !== oldEmail){
            UserPut(payload, profile.id, res=> {
                if(res === 'EMAIL_EXISTS'){
                    this.setState({email: oldEmail})
                }else{
                    set_profile_data(payload);
                    showNotification({
                        id: 'save_user_info_successful',
                        icon: <img src={GreenTick} alt='green-tick'/>,
                        message: 'Die Änderungen wurden übernommen.',
                    });
                    this.setState({openEmailVerificationModal: true, oldEmail: email});
                }
            });
        }
    }

    handleUpdatePassword=(e)=>{
        e.preventDefault();
        const {profile, account, set_profile_data} = this.props;
        const {currentPassword, newPassword} = this.state;
        let payload={
            accountId: account.accountId,
            newPassword: newPassword,
            oldPassword: currentPassword,
        }

        UserPasswordPut(payload, profile.id, res=> {
            showNotification({
                id: 'save_user_password_successful',
                icon: <img src={GreenTick} alt='green-tick'/>,
                message: 'Passwort erfolgreich aktualisiert.',
            });
            this.setState({newPassword: '', currentPassword: ''});
        });
    }

    render() {
        const {isMobile, isTablet, account} = this.props;
        const {firstName, lastName, email, openEmailVerificationModal, currentPassword, newPassword, oldEmail, openChangeUserEmailConfirmationModal} = this.state;
        console.log('account: ', account);
        return (
        <>
            <Stack>
                <HeadingContainer
                title={'Profil'}
                icon={ProfileIcon}
                rightButton
                rightButtonText={'Abmelden'}
                rightButtonClassName={'purple-btn btn-app-text'}
                rightButtonHandleClick={()=> this.handleLogout()}
                />
                <div className='divider'/>
                <Stack className='container'>
                    <Stack>
                        <Group className='profile-sub-heading'>Email-Adresse</Group>
                    </Stack>
                    <Stack style={{width: isMobile ? '100%' : isTablet ? '50%' : '33%'}}>
                        {/* <Group noWrap grow>
                            <TextInput
                                label="Vorname"
                                value={firstName}
                                onChange={this.handleFirstNameChange}
                                onBlur={()=> this.handleUpdate()}
                            />
                            <TextInput
                                label="Nachname"
                                value={lastName}
                                onChange={this.handleLastNameChange}
                                onBlur={()=> this.handleUpdate()}
                            />
                        </Group> */}
                        <Group grow>
                            <TextInput
                                //label="E-Mail Adresse "
                                value={email}
                                onChange={this.handleEmailChange}
                                onBlur={()=> this.setState({openChangeUserEmailConfirmationModal: true})}
                            />
                        </Group>
                    </Stack>
                    <Stack style={{marginTop: '25px'}}>
                        <Group className='profile-sub-heading'>Passwort ändern</Group>
                    </Stack>
                    <Stack style={{width: isMobile ? '100%' : isTablet ? '50%' : '33%'}}>
                        <Group grow>
                            <PasswordInput
                                label="Aktuelles Passwort"
                                value={currentPassword}
                                onChange={(e)=> this.setState({currentPassword: e.currentTarget.value})}
                                visibilityToggleIcon={({ reveal, size }) =>
                                reveal ? <img src={EyeIcon} size={size} /> : <img src={EyeIcon} size={size} />
                                }
                            />
                        </Group>
                        <Group grow>
                            <PasswordInput
                                label="Neues Passwort"
                                value={newPassword}
                                onChange={(e)=> this.setState({newPassword: e.currentTarget.value})}
                                visibilityToggleIcon={({ reveal, size }) =>
                                reveal ? <img src={EyeIcon} size={size} /> : <img src={EyeIcon} size={size} />
                                }
                            />
                        </Group>
                        <Group>
                            <Button onClick={this.handleUpdatePassword} className='purple-btn btn-app-text btn-app-text'>
                                Passwort ändern
                            </Button>
                        </Group>
                    </Stack>
                </Stack>
            </Stack>
            {openEmailVerificationModal &&
            <EmailVerificationModal
            open={openEmailVerificationModal}
            email={email}
            toggle={()=> this.setState({openEmailVerificationModal: false})}
            />}

            {openChangeUserEmailConfirmationModal &&
            <ChangeUserEmailConfirmationModal
            open={openChangeUserEmailConfirmationModal}
            newEmail={email}
            currentEmail={oldEmail}
            toggle={(cancel)=> this.setState({openChangeUserEmailConfirmationModal: false, email: cancel ? oldEmail : email})}
            confirm={()=> this.setState({openChangeUserEmailConfirmationModal: false},()=> this.handleUpdate())}
            />}
        </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      profile: state.generalReducer.profileData,
      account: state.generalReducer.accountData,
      isMobile: state.generalReducer.isMobile,
      isTablet: state.generalReducer.isTablet
    };
};

const actions = {
    set_profile_data,
    set_account_data,
    set_token,
    set_loader
};

export default connect(mapStateToProps, actions)(Profile);
