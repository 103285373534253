import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { Group, TextInput, Button, Stack, PasswordInput, Modal } from '@mantine/core';
import {set_profile_data, set_loader} from '../../../redux/action/General/generalActions';
import Logo from '../../../assests/svg/Logo.svg';
import EyeIcon from '../../../assests/svg/EyeIcon.svg';
import GreenTick from '../../../assests/svg/GreenTick.svg';
import RedCross from '../../../assests/svg/RedCross.svg';
import { EmailVerificationInstructionsPost, VerifyEmailPost } from '../../../helpers/fetchApis/Auth';
import { showNotification } from '@mantine/notifications';


const ChangeUserEmailConfirmationModal= (props) => {
    return (
    <Modal
    opened={props.open}
    centered
    overlayOpacity={0.2}
    onClose={() => props.toggle(true)}
    title="User email change confirmation">
        <Stack style={{width: '100%'}}>
            <Group><span className='card-sub-text'>Your current email address is <span style={{fontWeight: 'bold'}}>{props.currentEmail}</span> which will be replaced with <span style={{fontWeight: 'bold'}}>{props.newEmail}</span>. Do you confirm to make this change.</span></Group>
            <Group style={{justifyContent: 'space-between'}}>
                <Button className='gray-btn btn-app-text' onClick={()=> props.toggle(true)}>Abbrechen</Button>
                <Button className='purple-btn btn-app-text' onClick={()=> props.confirm()}>Confirm</Button>
            </Group>
        </Stack>
    </Modal>
    )
}

const actions = {
    set_profile_data,
    set_loader
};

export default connect(null, actions)(ChangeUserEmailConfirmationModal);
